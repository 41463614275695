h2 {
  font-weight: 400;
}

.create-container {
  padding: 24px;
}

.container-box {
  margin-bottom: 0 !important;
  padding: 15px;
  display: flex;
  justify-content: center;
}

.rsw-wrapper {
  min-height: 450px !important;
  padding: 0 !important;
}

.file-uploader-container {
  border: 1px dashed #c3c3c3;
  display: inline-flex;
  padding: 1rem;
}

.file-uploader-container .box {
  flex: 0 1 auto;
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  color: #575d63;
}

.file-uploader-container .divider {
  width: 1px;
  margin: 6px 0;
  background: #e1e1e1;
}

.file-uploader-container .btn-upload {
  border: 1px solid #0078a0;
  color: #0078a0;
  border-radius: 2px;
  margin: 5px;
}

.file-uploader-container .btn-upload:hover {
  color: #0078a0;
}

.online-uploader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.online-uploader-container img {
  height: 18px;
  margin-right: 10px;
}

.btn-continue {
  border: 1px solid #1ea2d5 !important;
  background-color: #1ea2d5 !important;
  color: white !important;
  border-radius: 2px !important;
  margin: 5px;
}

.btn-continue:hover {
  border: 1px solid #1a1a1a !important;
  background-color: #1a1a1a !important;
  color: white !important;
  border-radius: 2px !important;
  margin: 5px;
}

.btn-previous {
  border: 1px solid #1ea2d5 !important;
  background-color: white !important;
  color: #1ea2d5 !important;
  border-radius: 2px !important;
  margin: 5px;
}

@keyframes stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes rainbow {
  0% {
    background-color: var(--red);
  }

  20% {
    background-color: var(--orange);
  }

  40% {
    background-color: var(--yellow);
  }

  60% {
    background-color: var(--green);
  }

  80% {
    background-color: var(--blue);
  }

  100% {
    background-color: var(--indigo);
  }
}

@keyframes grow {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

/* .progress-wrapper {
  padding-top: 25%;
}

.progress {
  height: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 5px;
  width: 100%;
}

.loaded .progress-bar {
  animation: grow 3s ease, stripes 1s linear infinite, rainbow 3s ease infinite;
} */

.nav {
  justify-content: center;
}

.nav-bar {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 15px;
  position: relative;
}

/* Nav */

.dot {
  color: black;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 10px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.active {
  color: var(--blue);
  opacity: 1;
}

/* Transitions */
.animated {
  animation-duration: 0.8192s;
  animation-fill-mode: backwards;
  transform-style: preserve-3d;
}

@keyframes intro {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(0, 0, -50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.intro {
  animation: intro 1s ease-out;
}


@keyframes enterRight {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterRight {
  animation-name: enterRight;
}

@keyframes enterLeft {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterLeft {
  animation-name: enterLeft;
}

@keyframes exitRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(100%, 0, -100px);
  }
}

.exitRight {
  animation-name: exitRight;
}

@keyframes exitLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(-100%, 0, -100px);
  }
}

.exitLeft {
  animation-name: exitLeft;
}

.makeStyles-pdf_box-3 {
  display: block !important;
}