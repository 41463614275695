.header {
  top: 0;
  height: 120px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.esign_header {
  height: 60px !important;
  width: 100%;
  background-color:  #0f294b !important;
  padding: 8px 30px;
}

.esign_toolbar {
  height: 60px !important;
  width: 100%;
  background-color: rgb(232, 237, 244) !important;
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.onlyDownloadbtnDiv {
  justify-content: end !important;
}

.downloadBtn {
  border-radius: 0px !important;
}

.downloadIcon {
  margin-right: 5px;
}

@media screen and (max-width: 720px) {
  .esign_toolbar {
    flex-direction: column;
    height: 160px !important;
    overflow: scroll;
  }

  .header {
    position: fixed;
    height: auto;
    top: 0;
    z-index: 9;
  }

  .makeStyles-esign_form-1 {
    top: 220px !important;
  }
}

@media screen and (max-width: 400px) {
  .esign_toolbar {
    flex-direction: column;
    height: 210px !important;
    overflow: scroll;
  }

  .header {
    position: fixed;
    height: auto;
    top: 0;
    z-index: 9;
  }

  .makeStyles-esign_form-1 {
    top: 270px !important;
  }
}

@media screen and (max-width: 250px) {
  .esign_toolbar {
    flex-direction: column;
    height: 260px !important;
    overflow: scroll;
  }

  .header {
    position: fixed;
    height: auto;
    top: 0;
    z-index: 9;
  }

  .makeStyles-esign_form-1 {
    top: 320px !important;
  }
}
.esign_toolbar>h5 {
  margin-bottom: 0px !important;
}

.btn_reject {
  border-radius: 2px !important;
}

.swal-block .swal2-textarea {
  margin: 0 !important;
}

.swal-block .uk-form-label {
  margin-top: 1.5rem;
  font-size: 1.125em;
}

.swal_title {
  font-size: 1.2em;
}