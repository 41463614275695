.swal2-container {
    z-index: 9999 !important
}

.swal2-title{
    margin-top: 25px !important; 
}

.boatdox-label{
    font-size: x-large;
}

.submit-button {
    background-color: #28a745;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 9px;
    margin-right: 10px;
    text-transform: none;
    font-size: 14px;
    height: 36px;
    padding: 0 15px 0;
    line-height: 1;
}

.boatdox-label-div {
    margin-right: 22% !important;
}

.plus-icon{
    font-size: 1.5rem !important;
}

.add-engine{
    cursor: pointer;
    width: 27%;
}

.multiSelect .select__value-container {
    max-height: 240px;
    overflow: auto !important;
  }

.ant-picker-dropdown {
    z-index: 9999 !important;
  }