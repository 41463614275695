.toggle-switch {
    display: flex;
    height: 50%;
    border-radius: 2px;
    width: 60%;
}

.state-off {
    border-radius: 0px 5px 5px 0px;
}

.state-on {
    border-radius: 5px 0px 0px 6px;
}

.state-off,
.state-none,
.state-on {
    font-size: 1rem;
    width: 60%;
    /* transition: 0.5s; */
    display: grid;
    place-items: center;
    border: 2px solid;
    /* outline: 2px; */
}

.activated-state-on {
    background: rgb(0, 0, 0);
    color: rgb(253, 251, 251);
}

.activated-state-off {
    background: rgba(253, 251, 251);
    color: rgb(0, 0, 0);
}