.vessel-input {
    height: 2.8rem;
    font-size: 16px;
}

.searchBtn {
    color: white !important;
    background-color: black !important;
    height: fit-content !important;
}

.custom-drop {
    width: 500px !important;
    margin-left: 54px;
    margin-top: 15px;
}
.select-custom {
    width: 40% !important;
}
.show-dropdown {
    height: 300px !important;
    width: 490px !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    overflow: hidden !important;
}
.drop-down-div {
    margin-left: 65px;
}
.loader-div {
    width: 100%;
    height: 56px !important;
}
.dropdown-item{
    color: #004D89 !important   
}

.dropdown-item:active{
    background-color: #dee3e9 !important
}

.vessel-input .font-size {
    font-size: 13px;
    margin-top: 2.5px;
    height: 36px;
}
.custom-dropdown.dropdown-menu  {
    z-index: 9;
    right: 0;
}
.custom-dropdown.dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem 0 5px;
}