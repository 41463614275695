.form-drawer.ant-drawer-content-wrapper {
    width: 50% !important;
}

.ant-drawer-title {
    flex: 1 1;
    margin: 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.form-data-item {
    background-color: #e8edf4;
    border-radius: 5px;
    margin-bottom: 2px;
}

.panel-form .form-row .one-column {
    width: 33.33%;
}

.title-overlay-custom-class {
    z-index: 1201;
}


.modal-backdrop.show {
    z-index: 1000;
}

.formdata-fields-div {
    /* margin-top: 120px !important; */
}

.form-fields .css-10hburv-MuiTypography-root {
    font-size: 0.85rem !important;
}

.form-fields .css-yb0lig {
    font-size: 0.85rem !important;
}

.form-fields .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 0.85rem !important;
    padding: 0px 5px 0px 4px !important;
    min-height: 30.39px !important;
    width: 100% !important;
}

.form-fields .css-1x5jdmq {
    font-size: 0.85rem !important;
    padding: 0px 5px 0px 4px !important;
    min-height: 30.39px !important;
    width: 100% !important;
}