.react-pdf__Document {
  top     : 0;
  position: absolute;
  padding : 32px 5px 5px 5px;
}

// this css will apply when zoom going out of container(pdf-editor) on left side which is being hide now , for scroll that left hidden content 
.pdf-horizontal-scroll {
  left: 0 !important;
}

.react-pdf__Page {
  /* margin-top: 10px; */
  margin-bottom: 10px;
  width        : 100%;
  /* width: 856px !important; */
}

.react-pdf__Page__textContent {
  border       : 1px solid darkgrey;
  box-shadow   : 2px 2px 10px rgba(0, 0, 0, 0.5);
  /* border-radius: 5px; */
  box-sizing   : border-box;
}

.react-pdf__Page__annotations.annotationLayer {
  // display: none;
}

div[role=DocPanel] {
  z-index: 3 !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.page_container {
  display : flex;
  height  : 100%;
  width   : 100%;
  top     : 0;
  position: absolute;
}

.focused {
  border       : 2px solid #1976d2;
  border-radius: 4px;
}

.place-field {
  position: absolute;
  cursor: 'pointer';
  padding: 0;
  outline: none;
  border: 1px dash #565656;
}