.dealEdocTableHeader {
    text-transform: inherit !important;
}

.listPageSearch {
    width: 100%;
}

.add-button {
    width: 98px;
}

.formUkSearch {
    max-width: 251rem !important;
}

.form_drop_down {
    width: 15% !important;
}