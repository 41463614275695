.modal {
    border-radius: 20px;
}

.modalHeader {
    border-radius: inherit;
}

.modalTitle {
    margin-left: 65px;
}

.modalBody {
    display: contents;
    margin-left: 10px;
}

.modalFooter {
    border-radius: inherit;
}

.custom-h2 {
    font-size: 24px;
    /* Equivalent to 32px (adjust as needed) */
    font-weight: bold;
    letter-spacing: -.25px;
    margin-bottom: 5px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px; 
    /* Make the text bold */
    font-family: 'Arial', sans-serif;
    /* Use the font of your choice */
    color: #333;
    /* Set the color (dark grey in this case) */
    line-height: 1.4;
    /* Add some space above the paragraph */
}

.custom-h2 .text-red-vessels {
    font-weight: 500;
    color: red;
}