.signatureDiv {
    display: flex;
    justify-content: space-between;
}

.signatureTitle {
    width: 32rem;
}

.signatureDropDown {
    width: 15rem;
}

.sendSelectedBtn {
    margin-left: 24% !important;
}

.signature_esign {
    margin-top: 3%;
    padding-right: 3%;
    padding-left: 3%;
}

.doc_div {
    display: flex;
    flex-wrap: wrap;
}