
.mobile-container {
  height: 100%;
  display: flex;
  margin: 0 20px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-container svg {
  fill: #48c5fd;
  stroke: #2e7bfc;
  stroke-width: 1.5;
  height: 140px;
  width: 200px;
  margin-bottom: 25px;
}



.mobile-container h2 {
  color: hsl(0, 0%, 46%);
  font-size: 1em;
  font-weight: 400;
  margin-top: 10px;
  max-width: 670px;
}

.mobile-container h3 {
    margin-top: 15px;
    max-width: 670px;
  }
@media (prefers-reduced-motion: no-preference) {
    .gear-logo {
      animation: gear-logo-spin infinite 20s linear;
    }
  }

@keyframes gear-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
