.create-template-container {
  height: calc(100vh - 96px);

  .nav-bar {
    padding: 15px 0;
  }

  .rsw-wrapper {
    height: 100%;

    &>div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}