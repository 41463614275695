.autoSelectSearch {
    padding-top: 14px !important;
    margin-left: -45px !important;
}

.brokerDropDown {
    width: 18rem;
}

.addVesselH1 { 
    margin-top: 21px !important;
    white-space: pre;
    margin-right: 20px;
}
.custom-drop {
    width: 720px;
    margin-left: 54px;
    margin-top: 15px;
}
.select-custom {
    width: 40% !important;
}
.show-dropdown {
    height: 300px !important;
    overflow-y: scroll !important;
}
.drop-down-div {
    margin-left: 65px;
}
.loader-div {
    width: 100%;
    height: 56px !important;
}
.dropdown-item{
    color: #004D89 !important   
}

.dropdown-item:active{
    background-color: #dee3e9 !important
}