.checkbox-radio-div {
  margin-left: 1.5vh;
  gap: 5px;
  /* filter: blur; */
}

.error-div {
  font-size: medium;
}

.export-user-modal-loader {
  background-color: rgba(255, 255, 255, 0.452);
  z-index: 100;
}
