.doc_delete_icon {
    margin-left: 3px;
}

.delete_icon_link {
    display: block;
    margin-left: 10px;
    margin-bottom: 1px;
}

.copy_document {
    color: #1ea2d5;
    cursor: pointer;
}