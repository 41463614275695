@font-face {
  font-family: "wonderwall";
  src: local("wonderwall"), url("../../../assets/fonts/wonderwall.ttf") format("truetype");
}

@font-face {
  font-family: "Meddon";
  src: local("Meddon"), url("../../../assets/fonts/Meddon-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Signatura";
  src: local("Signatura"), url("../../../assets/fonts/Signatura\ Monoline.ttf") format("truetype");
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"), url("../../../assets/fonts/Caveat-SemiBold.ttf") format("truetype");
}

.uk-modal-dialog {
  margin-top: 100px;
  width: unset;
  max-width: 800px !important;
}

.signature-canvas {
  width: 600px;
  height: 240px;
  max-width: 100%;
  max-height: 100%;
  border: 1px dashed grey;
}

.signature-canvas>canvas {
  width: 100%;
  height: 100%;
}

.signature-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signature-text-input {
  background-color: inherit;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  border: none;
  border-bottom: 0.33px solid #848c9a;
  font-size: 36px;
  letter-spacing: .02em;
  height: 86px;
  max-height: 86px;
  font-weight: 400;
  caret-color: rgba(0, 0, 0, .5);
  text-align: center;
  opacity: .5;
  outline: none;
}

.signature-text-field {
  background-color: inherit;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border: 1px solid #929aa3 !important;
  font-size: 16px;
  letter-spacing: .02em;
  width: 100%;
  font-weight: 300;
  caret-color: rgba(0, 0, 0, .5);
  text-align: left;
  font-family: inherit;
  opacity: .8;
  outline: none;
}

.custom-container {
  position: relative;
  height: 80px;
  width: 100%;
}

.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.signature-input-field {
  cursor: pointer;
  width: 50px;
  height: 24px;
  margin-bottom: 0;
}

.check-label {
  font-size: larger;
}

.signature-text-hint {
  position: inherit;
  top: auto;
  width: 100%;
  text-align: center;
  transition: opacity .2s linear, visibility .2s;
  color: #606671;
  font-size: 16px;
  margin-bottom: 15px;
}

.signature-template-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.signature-template-box1 {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  padding: 15px;
  border-bottom: 1px solid #d8dfeb;
  border-top: 1px solid #d8dfeb;
  border-right: 1px solid #d8dfeb;
}

.signature-template-box2 {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  padding: 15px;
  border-bottom: 1px solid #d8dfeb;
  border-top: 1px solid #d8dfeb;
}

.signature-template-box3 {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  padding: 15px;
  border-bottom: 1px solid #d8dfeb;
  border-right: 1px solid #d8dfeb;
}

.signature-template-box4 {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  padding: 15px;
  border-bottom: 1px solid #d8dfeb;
}

.signature-template-radio {
  width: 24px;
  height: 24px;
}

.signature-template-label {
  font-size: 24px;
  margin: 0;
}