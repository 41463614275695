.rdt_Table {
    margin-bottom: 10rem !important;
}

.modal-table .rdt_Table {
    margin-bottom: 0 !important;
    font-size: 12px !important;
}


.modal-table .rdt_TableCol_Sortable {
    font-weight: 600;
}

.editSort {
    padding: 9px 15px 0px !important;
}

.modal-table .rdt_TableCell:has(> .selected-row) {
    background: rgb(206, 237, 247);
}